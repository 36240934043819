import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import parse from 'html-react-parser';
import SEO from '../components/SEO';

const HeaderStyles = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: clamp(400px, 1000px, 50vh);
  background-color: #095d89;
  color: #fff;
  section {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
  }
  h1 {
    font-size: clamp(26px, 4vw, 42px);
    line-height: clamp(30px, 7vw, 70px);
    margin: 0 auto;
    width: 90%;
    padding: 45px 0;
    position: relative;
    text-transform: uppercase;
    font-family: Public_Sans;
    font-weight: 800;
    position: relative;
    z-index: 2;

    /* &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &:before,
    &:after {
      content: '';
      width: 200px;
      height: 5px;
      background: #fff;
      position: absolute;
      left: 50%;
      margin-left: -100px;
    } */
  }
  .photo-copyright {
    position: absolute;
    bottom: 0;
    left: 1rem;
    z-index: 2;
    font-size: 9px;
  }
  @media (max-width: 767px) {
    height: 30vh;
    line-height: auto;
  }
`;

const BackgroundImageStyles = styled.div`
  background-size: cover;
  background-position: top;
  display: block;
  height: clamp(400px, 1000px, 50vh);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  margin: 0;
  overflow: hidden;
  @media (max-width: 767px) {
    height: 30vh;
  }
`;

function newsHeader(item) {
  return (
    <HeaderStyles>
      {item.mainImage ? (
        <BackgroundImageStyles>
          <GatsbyImage
            fluid={item.mainImage.asset.fluid}
            style={{ position: 'auto' }}
          />
        </BackgroundImageStyles>
      ) : (
        ''
      )}
      <section className="center">
        <h1>{parse(item.newsTitle)}</h1>
      </section>
      {item.imageAttribution ? (
        <p className="photo-copyright">{parse(item.imageAttribution)}</p>
      ) : (
        ''
      )}
    </HeaderStyles>
  );
}

export default function NewsItem({ location, data }) {
  const { newsItem } = data;

  return (
    <>
      <SEO location={location} title={newsItem.title} />
      {newsHeader(newsItem)}
      <main>
        <div className="navLink">
          <Link to="/news" className="homeLink">
            <span>Return to News List</span>
          </Link>
        </div>
        <p className="news-date">{newsItem.publishDate}</p>
        <p>{parse(newsItem.body)}</p>
      </main>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    newsItem: sanityNews(slug: { current: { eq: $slug } }) {
      id
      title
      newsTitle
      publishDate
      slug {
        current
      }
      shortDescription
      body
      mainImage {
        asset {
          fluid(maxWidth: 300) {
            ...GatsbySanityImageFluid
          }
        }
      }
      imageAttribution
    }
  }
`;
